import { FC } from 'react';
import { useAppSelector } from 'redux/hooks';

import { Nullable } from 'types/types';
import { getUserAvatar, getUserId } from 'redux/slices/user/exports';
import { UploadAvatarContainer } from './components/UploadAvatarContainer/UploadAvatarContainer';
import styles from './AvatarUploadingContainer.module.scss';
import { RemoveAvatarContainer } from './components/RemoveAvatarContainer/RemoveAvatarContainer';
import { USER_AVATAR_SRC } from 'constants/constants';

interface Props {
  handleUploadingAvatar: (uploadingAvatar: Nullable<File>) => void;
  uploadingAvatar: Nullable<File>;
}

export const AvatarUploadingContainer: FC<Props> = ({ handleUploadingAvatar, uploadingAvatar }) => {
  const userAvatar = useAppSelector(getUserAvatar);
  const userId = useAppSelector(getUserId);
  return (
    <div className={styles.AvatarUploadingContainer}>
      <UploadAvatarContainer
        handleUploadingAvatar={handleUploadingAvatar}
        uploadingAvatar={uploadingAvatar}
      />
      {userAvatar && <RemoveAvatarContainer />}
      <img draggable={false} src={USER_AVATAR_SRC(userId, userAvatar)} alt="" />
    </div>
  );
};
