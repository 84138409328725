import { FC } from 'react';
import { useAppSelector } from 'redux/hooks';

import { getUserSex } from 'redux/slices/user/exports';
import { classNames } from 'helpers/classNames';
import { useThunks } from 'redux/thunks/hooks/useThunks';
import styles from './GenderSymbol.module.scss';

type GenderType = 'male' | 'female';
type GenderSymbolMode = 'switcher' | 'icon';

interface Props {
  mode: GenderSymbolMode;
  type?: GenderType;
}

export const GenderSymbol: FC<Props> = ({ type, mode }) => {
  const userSex = useAppSelector(getUserSex);
  mode === 'icon' && (type = userSex);
  const { updateUserGender } = useThunks();
  const isSwitcherMode = mode === 'switcher';
  const isIconMode = mode === 'icon';
  const isMale = type === 'male';
  const isActive = isSwitcherMode && userSex === type;
  if (!isSwitcherMode && !userSex) return null;

  const onClickMethod = !isSwitcherMode ? undefined : () => updateUserGender(type);

  const maleSvg = (
    <svg
      className={classNames(styles.GenderSymbol, undefined, {
        [styles.switcherMode]: isSwitcherMode,
        [styles.active]: isActive,
        [styles.iconMode]: isIconMode,
      })}
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      onClick={onClickMethod}
    >
      <path
        fill="none"
        d="m83.417 18.329l.001-.005c0-.853-.69-1.544-1.542-1.545v-.016H61.078v.017a1.544 1.544 0 0 0-1.53 1.543v6.871c0 .847.684 1.534 1.53 1.542v.001h5.151l-8.713 8.713a26.051 26.051 0 0 0-14.761-4.56c-14.455 0-26.173 11.718-26.173 26.173S28.3 83.237 42.755 83.237c14.455 0 26.173-11.719 26.173-26.174a26.043 26.043 0 0 0-4.398-14.521l8.911-8.911v5.473c0 .853.691 1.544 1.544 1.544h6.887c.852 0 1.544-.691 1.544-1.544l-.001-.005v-20.77zM42.755 73.262c-8.946 0-16.198-7.252-16.198-16.199c0-8.946 7.252-16.199 16.198-16.199s16.199 7.253 16.199 16.199c0 8.947-7.253 16.199-16.199 16.199"
      />
    </svg>
  );
  const femaleSvg = (
    <svg
      className={classNames(styles.GenderSymbol, undefined, {
        [styles.switcherMode]: isSwitcherMode,
        [styles.active]: isActive,
        [styles.iconMode]: isIconMode,
      })}
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      onClick={onClickMethod}
    >
      <path
        fill="none"
        d="M68.507 55.658c10.221-10.221 10.221-26.793 0-37.016c-10.222-10.221-26.793-10.221-37.015 0c-10.222 10.223-10.222 26.795 0 37.016A26.054 26.054 0 0 0 44.9 62.823v8.09h-5.299a1.543 1.543 0 0 0-1.544 1.545v6.886c0 .852.692 1.543 1.544 1.544H44.9v6.592c0 .753.54 1.379 1.254 1.515v.029h7.177c.852 0 1.544-.691 1.544-1.544h.001v-6.592h5.523c.853 0 1.544-.691 1.544-1.544v-6.886a1.54 1.54 0 0 0-1.544-1.545h-5.522v-8.048a26.047 26.047 0 0 0 13.63-7.207M50 53.349c-8.946 0-16.199-7.252-16.198-16.198c0-8.946 7.252-16.199 16.199-16.199c8.946 0 16.199 7.252 16.198 16.198c-.001 8.946-7.253 16.199-16.199 16.199"
      />
    </svg>
  );
  return isMale ? maleSvg : femaleSvg;
};
