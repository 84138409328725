import { useAppSelector } from '../redux/hooks';

import { getCurrentLanguage, getIsFirstLoad } from 'redux/slices/main/exports';
import {
  getToken,
  getRecoveryCodeTimerTimestamp,
  getRecoverSessionTimerTimestamp,
  getRecoverySessionId,
  getPasswordRecoveringEmail,
} from 'redux/slices/user/exports';

import { ILocalStorageSave } from 'types/types';

export const useLocalStorageSave = () => {
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const token = useAppSelector(getToken);
  const recoveryCodeTimerTimestamp = useAppSelector(getRecoveryCodeTimerTimestamp);
  const recoverSessionTimerTimestamp = useAppSelector(getRecoverSessionTimerTimestamp);
  const recoverySessionId = useAppSelector(getRecoverySessionId);
  const passwordRecoveringEmail = useAppSelector(getPasswordRecoveringEmail);
  const isFirstLoad: boolean = useAppSelector(getIsFirstLoad);
  return () => {
    if (!isFirstLoad) {
      const saveData: ILocalStorageSave = {
        currentLanguage,
        token,
        recoveryCodeTimerTimestamp,
        recoverSessionTimerTimestamp,
        recoverySessionId,
        passwordRecoveringEmail,
      };
      localStorage.setItem('deguz-save', JSON.stringify(saveData));
    }
  };
};
