import { PAGE_CHANGE_DURATION } from 'constants/gsapConstants';
import { TransitionStatus } from 'react-transition-group';
import { Properties } from 'csstype';

const duration = PAGE_CHANGE_DURATION / 1.5;
// general
export const getSideMenuStyles = (isOpened: boolean) => {
  const sideMenuTotalDuration = duration * 2;
  const transitionDelay = isOpened ? 0 : sideMenuTotalDuration;
  const defaultStyle: Properties = {
    transition: `visibility 0s linear ${transitionDelay}s`,
    visibility: 'hidden',
  };
  const transitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { visibility: 'visible' },
    entered: { visibility: 'visible' },
    exiting: { visibility: 'hidden' },
    exited: { visibility: 'hidden' },
  };
  return { defaultStyle, transitionStyles, sideMenuTotalDuration: sideMenuTotalDuration * 1000 };
};

// background
export const getBackgroundStyles = (isOpened: boolean) => {
  const backgroundColorClosed = 'rgba(9, 9, 9, 0.0)';
  const backgroundColorOpened = 'rgba(9, 9, 9, 0.75)';
  const transitionDelay = isOpened ? 0 : duration;
  const backgroundDefaultStyle: Properties = {
    transition: `background ${duration}s ease-in-out ${transitionDelay}s`,
    backgroundColor: `${backgroundColorClosed}`,
  };
  const backgroundTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { backgroundColor: `${backgroundColorOpened}` },
    entered: { backgroundColor: `${backgroundColorOpened}` },
    exiting: { backgroundColor: `${backgroundColorClosed}` },
    exited: { backgroundColor: `${backgroundColorClosed}` },
  };
  return {
    backgroundDefaultStyle,
    backgroundTransitionStyles,
    backgroundTransitionDuration: duration * 1000,
  };
};

// side menu container
export const getSideMenuContainerStyles = (isOpened: boolean) => {
  const transitionDelay = !isOpened ? 0 : duration;
  const sideMenuContainerDefaultStyle: Properties = {
    transition: `transform ${duration}s ease-in-out ${transitionDelay}s, background-color ${duration}s ease-in-out, box-shadow ${duration}s ease-in-out`,
    transform: 'translateX(0%)',
  };
  const sideMenuContainerTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { transform: 'translateX(0%)' },
    entered: { transform: 'translateX(0%)' },
    exiting: { transform: 'translateX(100%)' },
    exited: { transform: 'translateX(100%)' },
  };
  return {
    sideMenuContainerDefaultStyle,
    sideMenuContainerTransitionStyles,
    sideMenuContainerTransitionDuration: duration * 1000,
  };
};
