import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { mainSlice } from './slices/main/mainSlice';
import { userSlice } from './slices/user/userSlice';
import { getPreloadedCurrentlyViewedPage, getPreloadedState } from './utils';
import { IS_PRODUCTION } from 'constants/constants';
import { combinedReducer } from './combinedReducer';

const {
  currentLanguage,
  token,
  recoveryCodeTimerTimestamp,
  recoverSessionTimerTimestamp,
  recoverySessionId,
  passwordRecoveringEmail,
  authStatus,
} = getPreloadedState();

export const store = configureStore({
  reducer: combinedReducer,
  preloadedState: {
    user: {
      ...userSlice.getInitialState(),
      token,
      recoveryCodeTimerTimestamp,
      recoverSessionTimerTimestamp,
      recoverySessionId,
      authStatus,
      passwordRecoveringEmail,
    },
    main: {
      ...mainSlice.getInitialState(),
      ...getPreloadedCurrentlyViewedPage(),
      currentLanguage,
    },
  },
  devTools: !IS_PRODUCTION,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
