import { FC, memo, useCallback, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';

import { FeedbackSendSection } from './components/FeedbackSendSection/FeedbackSendSection';
import { FeedbackTypeSwitchersContainer } from './components/FeedbackSendSection/components/FeedbackTypeSwitchersContainer/FeedbackTypeSwitchersContainer';
import { TextStyled } from 'components/TextStyled/TextStyled';
import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { InputRemastered } from 'components/InputRemastered/InputRemastered';
import { ButtonRemastered } from 'components/ButtonRemastered/ButtonRemastered';
import { getInputData } from 'utils/utils';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { AuthorizationDataValidationInfo } from 'components/AuthorizationDataValidationInfo/AuthorizationDataValidationInfo';
import { FeedbackType } from 'types/types';
import { EMPTY_STRING } from 'constants/constants';
import styles from './FeedbackPage.module.scss';

export const FeedbackPage: FC = memo(() => {
  const feedbackChoosingFeedbackTypeSectionRef = useRef<HTMLDivElement>(null);
  const feedbackContactsDataSectionRef = useRef<HTMLDivElement>(null);
  const feedbackTextDataSectionRef = useRef<HTMLDivElement>(null);
  const language = useLanguage();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const feedbackTypeChoosingText = language('feedbackTypeChoosingText');
  const yourEmailOptionallyText = `${language('yourEmailOptionallyCap')}:`;
  const feedbackTextParagraph = `${language('writeYourFeedbackInTheFieldBelowCap')}:`;
  const feedbackTextAreaPlaceholder = language('yourFeedbackCap');
  const MAXIMUM_FEEDBACK_TEXT_LENGTH = 255;
  const [selectedFeedbackType, setSelectedFeedbackType] = useState<FeedbackType>('offer');
  const [feedbackText, setFeedbackText] = useState(EMPTY_STRING);
  const [feedbackOwnerEmail, setFeedbackOwnerEmail] = useState(EMPTY_STRING);
  const { inputType: yourEmailInputType, placeholder: yourEmailInputPlaceholder } = getInputData(
    'email',
    false,
    false,
    false,
    currentLanguage,
  );

  const handleFeedbackTypeChange = useCallback((feedbackType: FeedbackType) => {
    setSelectedFeedbackType(feedbackType);
  }, []);

  const handleFeedbackText: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    value?.length <= MAXIMUM_FEEDBACK_TEXT_LENGTH && setFeedbackText(value);
  };

  const handleEmailOwnerEmail: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({
    target: { value },
  }) => {
    setFeedbackOwnerEmail(value);
  };
  return (
    <div className={styles.FeedbackPage}>
      <FeedbackSendSection>
        <TextStyled text={feedbackTextParagraph} />
        <InputRemastered
          type="textarea"
          textAreaRowsCount={4}
          value={feedbackText}
          placeholder={feedbackTextAreaPlaceholder}
          maxLength={MAXIMUM_FEEDBACK_TEXT_LENGTH}
          isTextAreaWithLimitShowingBox
          onChange={handleFeedbackText}
        />
        <TextStyled text={yourEmailOptionallyText} />
        <InputRemastered
          type={yourEmailInputType}
          placeholder={yourEmailInputPlaceholder}
          value={feedbackOwnerEmail}
          onChange={handleEmailOwnerEmail}
        />
        <ButtonRemastered buttonText="Send" isFullWidth isDisabled={true} />
        <AuthorizationDataValidationInfo validationError="" />
        {/* <FeedbackSemanticBlock ref={feedbackChoosingFeedbackTypeSectionRef}>
          <TextStyled text={feedbackTypeChoosingText} />
          <FeedbackTypeSwitchersContainer
            selectedFeedbackType={selectedFeedbackType}
            handleFeedbackTypeChange={handleFeedbackTypeChange}
          />
        </FeedbackSemanticBlock> */}
        {/* <FeedbackSemanticBlock ref={feedbackContactsDataSectionRef}>
          <TextStyled text={yourEmailOptionallyText} />
          <InputRemastered
            type={yourEmailInputType}
            placeholder={yourEmailInputPlaceholder}
            value=""
          />
        </FeedbackSemanticBlock>
        <FeedbackSemanticBlock ref={feedbackTextDataSectionRef}>
          <TextStyled text={feedbackText} />
          <InputRemastered type="textarea" textAreaRowsCount={4} value="" />
        </FeedbackSemanticBlock> */}
        {/* <ButtonRemastered buttonText="Send" isFullWidth isDisabled={true} />
        <AuthorizationDataValidationInfo validationError="" /> */}
      </FeedbackSendSection>
    </div>
  );
});
