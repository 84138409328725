import { IObjectOfObjects } from 'types/types';
import { ITranslationsObject } from './index';

export const IMAGES_ALT_TEXT_TRANSLATIONS: IObjectOfObjects<ITranslationsObject> = {
  roundedUpArrow: {
    en: 'rounded up arrow',
    ru: 'скруглённая стрелка вверх',
  },
  heart: {
    en: 'a heart (love)',
    ru: 'сердце (любовь)',
  },
  recycleBin: {
    en: 'recycle bin',
    ru: 'мусорная корзина',
  },
  greenLightBulb: {
    en: 'a green light bulb',
    ru: 'зеленая лампочка',
  },
  redExclamationMarkInRedTriangle: {
    en: 'a red exclamation mark in a red triangle',
    ru: 'красный восклицательный знак в красном треугольнике',
  },
  yellowStar: {
    en: 'a yellow star',
    ru: 'желтая звезда',
  },
  whiteArrowInBlackCircle: {
    en: 'a white down arrow in a black circle',
    ru: 'белая стрелка вниз в чёрном круге',
  },
};
