import { ChangeEventHandler, FC, memo, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';

import { InputRemastered } from 'components/InputRemastered/InputRemastered';
import { PasswordRecoveryModeDisabler } from '../PasswordRecoveryModeDisabler/PasswordRecoveryModeDisabler';
import { ButtonRemastered } from 'components/ButtonRemastered/ButtonRemastered';
import { CodeConfirmationTimer } from 'components/CodeConfirmationTimer/CodeConfirmationTimer';
import { PASSWORD_RECOVERY_SESSION_AWAITING_TIME } from 'constants/constants';
import { usePasswordRecovery } from 'hooks/usePasswordRecovery';
import { getRecoverSessionTimerTimestamp } from 'redux/slices/user/exports';
import { CHANGING_PASSWORD_DATA_DEFAULTS } from './constants';
import { useThunks } from 'redux/thunks/hooks/useThunks';
import { getPasswordValidationError } from 'pages/hooks/useAuthorizationDataError';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { language } from 'utils/language';
import { AuthorizationDataValidationInfo } from 'components/AuthorizationDataValidationInfo/AuthorizationDataValidationInfo';
import styles from './ChangingPasswordBlock.module.scss';

interface Props {
  isChangingPasswordBlockShown: boolean;
}

export interface IChangingPasswordData {
  newPassword: string;
  newPasswordRepeated: string;
}

export const ChangingPasswordBlock: FC<Props> = memo(({ isChangingPasswordBlockShown }) => {
  const recoverSessionTimerTimestamp = useAppSelector(getRecoverSessionTimerTimestamp);
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const { clearPasswordRecoveringData } = usePasswordRecovery();
  const { changePasswordInRecoveryMode } = useThunks();
  const buttonText = language(currentLanguage, 'passwordConfirmCap');
  const newPasswordInputPlaceholder = language(currentLanguage, 'newPasswordCap');
  const passwordRepeatInputPlaceholder = language(currentLanguage, 'onceAgainCap');
  const [changingPasswordData, setChangingPasswordData] = useState(CHANGING_PASSWORD_DATA_DEFAULTS);
  const { newPassword, newPasswordRepeated } = changingPasswordData;
  const { isValidatedFieldEmpty, validationError } = getPasswordValidationError(
    newPassword,
    newPasswordRepeated,
    currentLanguage,
  );
  const isApplyButtonDisabled = isValidatedFieldEmpty || Boolean(validationError);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, name } = event.target;
    setChangingPasswordData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleConfirmClick = () => {
    if (isApplyButtonDisabled) return;
    changePasswordInRecoveryMode(newPassword);
  };

  useEffect(() => {
    !isChangingPasswordBlockShown &&
      (newPassword || newPasswordRepeated) &&
      setChangingPasswordData(CHANGING_PASSWORD_DATA_DEFAULTS);
  }, [isChangingPasswordBlockShown, newPassword, newPasswordRepeated]);
  return (
    <div className={styles.ChangingPasswordBlock}>
      <InputRemastered
        value={newPassword}
        type={'password'}
        placeholder={newPasswordInputPlaceholder}
        onChange={handleChange}
        onEnter={handleConfirmClick}
        name="newPassword"
      />
      <InputRemastered
        value={newPasswordRepeated}
        type={'password'}
        placeholder={passwordRepeatInputPlaceholder}
        onChange={handleChange}
        onEnter={handleConfirmClick}
        name="newPasswordRepeated"
        isRepeatedPassword
      />
      <PasswordRecoveryModeDisabler />
      <ButtonRemastered
        isFullWidth
        isDisabled={isApplyButtonDisabled}
        buttonText={buttonText}
        onClick={handleConfirmClick}
      />
      <CodeConfirmationTimer
        timerDurationMilliseconds={PASSWORD_RECOVERY_SESSION_AWAITING_TIME}
        isTimerStarted={isChangingPasswordBlockShown}
        limiterTimestamp={recoverSessionTimerTimestamp}
        onEndCallback={clearPasswordRecoveringData}
      />
      <AuthorizationDataValidationInfo validationError={validationError} />
    </div>
  );
});
