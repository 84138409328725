import { FC } from 'react';
import { useAppSelector } from 'redux/hooks';

import { getGeneralAppStatus } from 'redux/reselect/reselect';
import { classNames } from 'helpers/classNames';
import styles from './Loader.module.scss';

export const Loader: FC = () => {
  const isLoading = useAppSelector(getGeneralAppStatus) === 'loading';
  return <div className={classNames(styles.Loader, [], { [styles.shown]: isLoading })}></div>;
};
