import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';

import { ModalWindowStatusType, Nullable } from 'types/types';
import { getIsModalWindowAcceptingType, getModalWindowAcceptanceText } from '../utils';
import { useLogout } from 'hooks/useLogout';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { AvatarUploadingContainer } from '../components/AvatarUploadingContainer/AvatarUploadingContainer';
import { useThunks } from 'redux/thunks/hooks/useThunks';

export const useModalWindow = (modalWindowStatus: ModalWindowStatusType) => {
  const [uploadingAvatar, setUploadingAvatar] = useState<Nullable<File>>(null);
  const [isModalWindowClosing, setIsModalWindowClosing] = useState(false);
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(false);
  const { updateUserAvatar } = useThunks();

  const startClosingModalWindow = () => {
    setIsModalWindowClosing(true);
  };

  const handleApplyButtonState = (isApplyButtonDisabled: boolean) => {
    setIsApplyButtonDisabled(isApplyButtonDisabled);
  };

  const resetModalWindowState = () => {
    setUploadingAvatar(null);
    setIsModalWindowClosing(false);
    setIsApplyButtonDisabled(false);
  };

  const handleUploadingAvatar = (uploadingAvatar: Nullable<File>) => {
    setUploadingAvatar(uploadingAvatar);
  };

  const currentLanguage = useAppSelector(getCurrentLanguage);
  const isModalWindowAcceptingType = getIsModalWindowAcceptingType(modalWindowStatus);
  const logOut = useLogout();
  const modalWindowAcceptMethod =
    !isModalWindowAcceptingType || isApplyButtonDisabled
      ? undefined
      : (() => {
          switch (modalWindowStatus) {
            case 'log out':
              return logOut;
            case 'avatar':
              return !uploadingAvatar
                ? undefined
                : async () => {
                    const isSucceed = await updateUserAvatar(uploadingAvatar);
                    isSucceed && setUploadingAvatar(null);
                  };
          }
        })();
  const modalWindowAcceptanceText = getModalWindowAcceptanceText(
    currentLanguage,
    modalWindowStatus,
  );
  const modalWindowExtraComponent = (() => {
    switch (modalWindowStatus) {
      case 'avatar':
        return (
          <AvatarUploadingContainer
            handleUploadingAvatar={handleUploadingAvatar}
            uploadingAvatar={uploadingAvatar}
          />
        );
      default:
        return null;
    }
  })();

  useEffect(() => {
    if (modalWindowStatus === 'avatar') {
      setIsApplyButtonDisabled(uploadingAvatar ? false : true);
    }
  }, [modalWindowStatus, uploadingAvatar]);
  return {
    isModalWindowAcceptingType: isModalWindowAcceptingType,
    modalWindowAcceptanceText: modalWindowAcceptanceText,
    modalWindowAcceptMethod,
    modalWindowExtraComponent,
    startClosingModalWindow,
    resetModalWindowState,
    isModalWindowClosing,
    isApplyButtonDisabled,
    handleApplyButtonState,
  };
};
