import { AnimationEventHandler, FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { classNames } from 'helpers/classNames';
import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { RoutesEnum } from 'router/routes';
import styles from './HeartOrnamental.module.scss';

export const HeartOrnamental: FC = () => {
  const language = useLanguage();
  const [isHeartRendered, setIsHeartRendered] = useState(false);
  const strokeDasharray = 310;
  const strokeWidth = 1;
  const thankfulText = language('thankYou');
  const donationPageSrc = isHeartRendered
    ? 'https://www.paypal.com/donate/?hosted_button_id=KFTRBPZUGT7ZA'
    : RoutesEnum.SUPPORT_URL;

  const setHeartRendered = () => {
    !isHeartRendered && setIsHeartRendered(true);
  };

  const handleHeartRendered: AnimationEventHandler<SVGSVGElement> = () => {
    setHeartRendered();
  };
  return (
    <div className={styles.HeartOrnamental}>
      <p
        className={classNames(styles.thankYouText, undefined, {
          [styles.shown]: isHeartRendered,
        })}
      >
        <Link draggable={false} to={donationPageSrc} className={styles.donateButton} role="button">
          <span>Donate</span>
        </Link>
        <span>{thankfulText}</span>
      </p>
      <svg
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        onAnimationEnd={handleHeartRendered}
      >
        <path
          d="M100,170 C0,100 30,40 100,70 C170,40 200,100 100,170 Z"
          stroke="#ff0000"
          strokeWidth={strokeWidth}
          fill="none"
          strokeLinecap="round"
          strokeDasharray={strokeDasharray}
        ></path>
      </svg>
    </div>
  );
};
