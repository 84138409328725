import { PAGE_CHANGE_DURATION } from 'constants/gsapConstants';
import { TransitionStatus } from 'react-transition-group';
import { Properties } from 'csstype';

const duration = PAGE_CHANGE_DURATION / 2;
// general
export const getModalWindowVisibilityStyles = (isModalWindowShown: boolean) => {
  const modalWindowTotalDuration = duration * 2;
  const transitionDelay = isModalWindowShown ? 0 : modalWindowTotalDuration;
  const modalWindowVisibilityDefaultStyle: Properties = {
    transition: `visibility 0s linear ${transitionDelay}s`,
    visibility: 'hidden',
  };
  const modalWindowVisibilityTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { visibility: 'visible' },
    entered: { visibility: 'visible' },
    exiting: { visibility: 'hidden' },
    exited: { visibility: 'hidden' },
  };
  return {
    modalWindowVisibilityDefaultStyle,
    modalWindowVisibilityTransitionStyles,
    modalWindowVisibilityDuration: modalWindowTotalDuration * 1000,
  };
};

// background
export const getBackgroundStyles = (isModalWindowShown: boolean) => {
  const transitionDelay = isModalWindowShown ? 0 : duration;
  const backgroundDefaultStyle: Properties = {
    transition: `opacity ${duration}s ease-in-out ${transitionDelay}s`,
    opacity: 0,
  };
  const backgroundTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };
  return {
    backgroundDefaultStyle,
    backgroundTransitionStyles,
    backgroundTransitionDuration: duration * 1000,
  };
};

// modal window container
export const getModalWindowContainerStyles = (isModalWindowShown: boolean) => {
  const transitionDelay = !isModalWindowShown ? 0 : duration;
  const modalWindowContainerDefaultStyle: Properties = {
    transition: `opacity ${duration}s ease-in-out ${transitionDelay}s, transform ${
      duration * 2
    }s ease-in-out ${transitionDelay * 2}s`,
    opacity: 0,
    transform: 'scale(0.9) translateY(-15px)',
  };
  const modalWindowContainerTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { transform: 'scale(1) translateY(0)', opacity: 1 },
    entered: { transform: 'scale(1) translateY(0)', opacity: 1 },
    exiting: { transform: 'scale(0.9) translateY(-15px)', opacity: 0 },
    exited: { transform: 'scale(0.9) translateY(-15px)', opacity: 0 },
  };
  return {
    modalWindowContainerDefaultStyle,
    modalWindowContainerTransitionStyles,
    modalWindowContainerTransitionDuration: duration * 1000,
  };
};
