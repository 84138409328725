import {
  ACCENTS_THEME_COLOR,
  MAIN_THEME_COLOR,
  PAGE_CHANGE_DURATION,
} from 'constants/gsapConstants';
import { TransitionStatus } from 'react-transition-group';
import { Properties } from 'csstype';

const duration = PAGE_CHANGE_DURATION;
export const getNavLinkOpacityStyles = (isNavLinksGroupCurrent: boolean) => {
  const delay = isNavLinksGroupCurrent ? duration : 0;
  const navLinkOpacityDefaultStyle: Properties = {
    transition: `opacity ${duration}s linear ${delay}s`,
    opacity: 0,
  };
  const navLinkOpacityTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { opacity: 0 },
    entered: { opacity: 0 },
    exiting: { opacity: 1 },
    exited: { opacity: 1 },
  };
  return {
    navLinkOpacityDefaultStyle,
    navLinkOpacityTransitionStyles,
    navLinkOpacityDuration: duration * 1000,
  };
};
export const getNavLinkColorStyles = (isMainAppLink: boolean) => {
  const notSelectedColor = isMainAppLink ? 'transparent' : MAIN_THEME_COLOR;
  const selectedColor = ACCENTS_THEME_COLOR;
  const navLinkColorDefaultStyle: Properties = {
    transition: `color ${duration}s linear`,
    color: notSelectedColor,
  };
  const navLinkColorTransitionStyles: Partial<Record<TransitionStatus, Properties>> = {
    entering: { color: notSelectedColor },
    entered: { color: notSelectedColor },
    exiting: { color: selectedColor },
    exited: { color: selectedColor },
  };
  return {
    navLinkColorDefaultStyle,
    navLinkColorTransitionStyles,
    navLinkColorDuration: duration * 1000,
  };
};
