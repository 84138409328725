import { createSelector } from '@reduxjs/toolkit';

import { getAuthStatus, getUpdateStatus } from 'redux/slices/user/exports';
import { RequestStatusType } from 'redux/types';

export const getGeneralAppStatus = createSelector(
  [getAuthStatus, getUpdateStatus],
  (authStatus, updateStatus): RequestStatusType => {
    return authStatus === 'loading' || updateStatus === 'loading' ? 'loading' : 'idle';
  },
);
