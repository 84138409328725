import {
  ChangeEventHandler,
  FC,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  useState,
} from 'react';

import { PasswordVisibilitySwitcher } from 'components/PasswordVisibilitySwitcher/PasswordVisibilitySwitcher';
import styles from './InputRemastered.module.scss';
import { classNames } from 'helpers/classNames';

interface Props {
  value: string;
  type: HTMLInputTypeAttribute | 'textarea';
  placeholder?: string;
  onEnter?: VoidFunction;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  name?: string;
  isRepeatedPassword?: boolean;
  textAreaRowsCount?: number;
  maxLength?: number;
  isTextAreaWithLimitShowingBox?: boolean;
}

export const InputRemastered: FC<Props> = ({
  type,
  value,
  onEnter,
  onChange,
  placeholder = '',
  name,
  isRepeatedPassword = false,
  textAreaRowsCount = 1,
  maxLength,
  isTextAreaWithLimitShowingBox,
}) => {
  const [isPasswordShown, setPasswordShown] = useState(false);
  const isPasswordVisibilitySwitcherDisplayed =
    type === 'password' && !isRepeatedPassword && Boolean(value);
  const inputType = isPasswordShown ? 'text' : type;
  const isTextAreaFulfilled = maxLength && maxLength === value?.length;

  const onEnterPress: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.key !== 'Enter') return;
    onEnter && onEnter();
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!isPasswordShown);
  };
  return (
    <div className={styles.InputRemastered}>
      {inputType !== 'textarea' ? (
        <input
          className={styles.InputRemastered}
          type={inputType}
          value={value}
          onChange={onChange}
          onKeyDown={onEnterPress}
          placeholder={placeholder}
          spellCheck={false}
          name={name}
          maxLength={maxLength}
        />
      ) : (
        <textarea
          className={styles.InputRemastered}
          value={value}
          onChange={onChange}
          onKeyDown={onEnterPress}
          placeholder={placeholder}
          spellCheck={false}
          rows={textAreaRowsCount}
          name={name}
          maxLength={maxLength}
        />
      )}
      {isTextAreaWithLimitShowingBox && maxLength && <div className={styles.textAreaShovingBox}>
        <span className={classNames(styles.currentTextAreaLengthSpan, undefined, {[styles.fulfilled]: isTextAreaFulfilled})}>{value?.length}</span>
        <span> / </span>
        <span className={styles.maxTextAreaLengthSpan}>{maxLength}</span>
      </div>}
      <PasswordVisibilitySwitcher
        isDisplayed={isPasswordVisibilitySwitcherDisplayed}
        isPasswordShown={isPasswordShown}
        onClickToggler={togglePasswordVisibility}
      />
    </div>
  );
};
