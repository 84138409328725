import { ChangeEventHandler, FC, memo, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';

import { PasswordRecoveryModeDisabler } from '../PasswordRecoveryModeDisabler/PasswordRecoveryModeDisabler';
import { ButtonRemastered } from 'components/ButtonRemastered/ButtonRemastered';
import { InputRemastered } from 'components/InputRemastered/InputRemastered';
import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { useThunks } from 'redux/thunks/hooks/useThunks';
import { getRecoveryCodeTimerTimestamp } from 'redux/slices/user/exports';
import { CodeConfirmationTimer } from 'components/CodeConfirmationTimer/CodeConfirmationTimer';
import { RECOVERY_CODE_AWAITING_TIME } from 'constants/constants';
import { getCurrentlyViewedPage } from 'redux/slices/main/exports';
import { usePasswordRecovery } from 'hooks/usePasswordRecovery';
import styles from './CodeConfirmationBlock.module.scss';

interface Props {
  isConfirmationRecoveryCodeBlockShown: boolean;
}

export const CodeConfirmationBlock: FC<Props> = memo(({ isConfirmationRecoveryCodeBlockShown }) => {
  const language = useLanguage();
  const recoveryCodeTimerTimestamp = useAppSelector(getRecoveryCodeTimerTimestamp);
  const isOnSignInPage = useAppSelector(getCurrentlyViewedPage) === 'signin';
  const isTimerStarted =
    Boolean(recoveryCodeTimerTimestamp) && isOnSignInPage && isConfirmationRecoveryCodeBlockShown;
  const [recoveryCode, setRecoveryCode] = useState('');
  const inputPlaceholder = language('enterCodeCap');
  const buttonText = language('confirmCodeCap');
  const { confirmRecoveryCode } = useThunks();
  const { clearPasswordRecoveringData } = usePasswordRecovery();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setRecoveryCode(value);
  };

  const handleConfirmClick = () => {
    if (!recoveryCode) return;
    confirmRecoveryCode(recoveryCode);
  };

  useEffect(() => {
    !isConfirmationRecoveryCodeBlockShown && recoveryCode && setRecoveryCode('');
  }, [isConfirmationRecoveryCodeBlockShown, recoveryCode]);
  return (
    <div className={styles.CodeConfirmationBlock}>
      <InputRemastered
        value={recoveryCode}
        type={'text'}
        placeholder={inputPlaceholder}
        onChange={handleChange}
        onEnter={handleConfirmClick}
      />
      <PasswordRecoveryModeDisabler />
      <ButtonRemastered
        isFullWidth
        isDisabled={!recoveryCode}
        buttonText={buttonText}
        onClick={handleConfirmClick}
      />
      <CodeConfirmationTimer
        timerDurationMilliseconds={RECOVERY_CODE_AWAITING_TIME}
        isTimerStarted={isTimerStarted}
        limiterTimestamp={recoveryCodeTimerTimestamp}
        onEndCallback={clearPasswordRecoveringData}
      />
    </div>
  );
});
