import { MutableRefObject } from 'react';
import { useAppSelector } from 'redux/hooks';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import { getIsLanguageSwitcherRolled } from 'redux/slices/main/exports';
import { ACCENTS_THEME_COLOR, MAIN_THEME_COLOR, ZERO_GSAP_DURATION } from 'constants/gsapConstants';

export const useAnimateHighlighting = (
  isCurrentLanguage: boolean,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const isLanguageSwitcherRolled = useAppSelector(getIsLanguageSwitcherRolled);
  const selectedColor = isCurrentLanguage ? ACCENTS_THEME_COLOR : MAIN_THEME_COLOR;
  const selectedTextShadow = isCurrentLanguage
    ? '0 0 3px rgba(156, 48, 227, 0.7)'
    : '0 0 0 rgba(132, 22, 194, 0.7)';
  const cursorType = isCurrentLanguage || !isLanguageSwitcherRolled ? 'default' : 'pointer';
  const { timeline: highlightingTimeline } = useGSAPTimeline(false, undefined, scopeElement, [
    isCurrentLanguage,
  ]);
  const HIGHLIGHTING_LANGUAGE_DURATION = 0.3;
  highlightingTimeline &&
    highlightingTimeline
      .to(scopeElement.current, {
        color: selectedColor,
        textShadow: selectedTextShadow,
        duration: HIGHLIGHTING_LANGUAGE_DURATION,
      })
      .to(
        scopeElement.current,
        {
          cursor: cursorType,
          duration: ZERO_GSAP_DURATION,
        },
        '<',
      );
};
