import { MainState } from './types';

export const mainSliceInitialState: MainState = {
  currentLanguage: 'en',
  theme: 'dark',
  isFirstLoad: true,
  isAuthModalActive: false,
  isContentContainerHovered: false,
  isUsersSearchResultsShown: false,
  userSearchKey: '',
  foundUsers: [],
  searchUsersStatus: 'idle',
  currentlyViewedPage: 'home',
  isLanguageSwitcherRolled: false,
  onlineUsersNicknames: [],
  openedAssessmentRegistration: null,
  isUserSideMenuOpened: false,
  modalWindowStatus: 'closed',
  areHomePageWidgetsActive: false,
};
