import { FC, useState } from 'react';

import { useAppSelector } from 'redux/hooks';
import { getUserAvatar, getUserId } from 'redux/slices/user/exports';
import { USER_DEFAULT_AVATAR_ICON_SRC } from 'constants/assets-src';
import { SmallLoader } from 'components/SmallLoader/SmallLoader';
import { classNames } from 'helpers/classNames';
import { USER_AVATAR_SRC } from 'constants/constants';
import styles from './UserAvatarImage.module.scss';

export const UserAvatarImage: FC = () => {
  const userAvatar = useAppSelector(getUserAvatar);
  const userId = useAppSelector(getUserId);
  const [isLoaded, setIsLoaded] = useState(false);
  const userAvatarSrc = !userAvatar
    ? USER_DEFAULT_AVATAR_ICON_SRC
    : USER_AVATAR_SRC(userId, userAvatar);

  const onLoadHandler = () => {
    setIsLoaded(true);
  };
  return (
    <div className={styles.UserAvatarImage}>
      {!isLoaded && <SmallLoader />}
      <img
        draggable={false}
        className={classNames(styles.avatarImage, undefined, { [styles.loaded]: isLoaded })}
        src={userAvatarSrc}
        alt=""
        onLoad={onLoadHandler}
      />
    </div>
  );
};
