import { FC, memo, useRef } from 'react';

import { HeartOrnamental } from './components/HeartOrnamental/HeartOrnamental';
import styles from './SupportPage.module.scss';

export const SupportPage: FC = memo(() => {
  const aboutMePageRef = useRef(null);
  return (
    <div className={styles.SupportPage} ref={aboutMePageRef}>
      <HeartOrnamental />
    </div>
  );
});
