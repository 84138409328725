import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import {
  getLoginData,
  getPasswordRecoveringEmail,
  getRecoverySessionId,
  getToken,
  getUserId,
} from 'redux/slices/user/exports';
import {
  checkPasswordRecoveringCodeAsync,
  recoverUserPasswordAsync,
  recoveryUserPasswordChangeAsync,
  updateUserAvatarAsync,
  updateUserSexAsync,
  userLoginAsync,
} from '../userThunks';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { Nullable, UserSexType } from 'types/types';
import { IGeneralResponse } from '../types';

export const useThunks = () => {
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const token = useAppSelector(getToken);
  const userId = useAppSelector(getUserId);
  const loginData = useAppSelector(getLoginData);
  const passwordRecoveringEmail = useAppSelector(getPasswordRecoveringEmail);
  const recoverySessionId = useAppSelector(getRecoverySessionId);
  const { email: loginEmail } = loginData;

  const login = useCallback(
    () => dispatch(userLoginAsync({ ...loginData, lang: currentLanguage })),
    [currentLanguage, dispatch, loginData],
  );

  const getPasswordRecoveryCode = useCallback(
    () => dispatch(recoverUserPasswordAsync({ email: loginEmail, lang: currentLanguage })),
    [currentLanguage, dispatch, loginEmail],
  );

  const confirmRecoveryCode = useCallback(
    (recoveryCode: string) => {
      dispatch(
        checkPasswordRecoveringCodeAsync({
          email: passwordRecoveringEmail,
          lang: currentLanguage,
          recoveryCode,
        }),
      );
    },
    [currentLanguage, dispatch, passwordRecoveringEmail],
  );

  const changePasswordInRecoveryMode = useCallback(
    (newPassword: string) => {
      dispatch(
        recoveryUserPasswordChangeAsync({
          email: passwordRecoveringEmail,
          lang: currentLanguage,
          newPassword,
          recoverySessionId,
        }),
      );
    },
    [currentLanguage, dispatch, passwordRecoveringEmail, recoverySessionId],
  );

  const updateUserAvatar = useCallback(
    async (userAvatar?: Nullable<File>) => {
      const requestData = new FormData();
      requestData.set('avatar', userAvatar || '');
      const result = await dispatch(
        updateUserAvatarAsync({ lang: currentLanguage, token, userId, requestData }),
      );
      const { statusCode } = result.payload as IGeneralResponse;
      return statusCode === 200;
    },
    [currentLanguage, dispatch, token, userId],
  );

  const updateUserGender = useCallback(
    async (userSex: UserSexType) => {
      const result = await dispatch(
        updateUserSexAsync({ lang: currentLanguage, token, userId, userSex }),
      );
      const { statusCode } = result.payload as IGeneralResponse;
      return statusCode === 200;
    },
    [currentLanguage, dispatch, token, userId],
  );

  return {
    login,
    getPasswordRecoveryCode,
    confirmRecoveryCode,
    changePasswordInRecoveryMode,
    updateUserAvatar,
    updateUserGender,
  };
};
