import homeLogo from '../assets/img/home-logo.svg';
import errorRoundIcon from '../assets/img/error-round.svg';
import closedEyeIcon from '../assets/img/eye-hidden.svg';
import openedEyeIcon from '../assets/img/eye-shown.svg';
import arrowRounded from '../assets/img/arrow-top.svg';
import heart from '../assets/img/heart.svg';
import acceptIcon from '../assets/img/accept-icon.svg';
import crossIcon from '../assets/img/cross-icon.svg';
import userDefaultAvatarIcon from '../assets/img/user-default-avatar.svg';
import greenPlusIcon from '../assets/img/green-plus.svg';
import gearWheelIcon from '../assets/img/gear-wheel-icon.svg';
import recycleBinIcon from '../assets/img/recycle-bin.svg';
import heartRedIcon from '../assets/img/heart-red.svg';
import feedbackIcon from '../assets/img/feedback.svg';
import userOnlineIcon from '../assets/img/user-online.svg';
import offerFeedbackIcon from '../assets/img/offer.svg';
import remarkFeedbackIcon from '../assets/img/remark.svg';
import complimentFeedbackIcon from '../assets/img/compliment.svg';
import arrowInBlackCircleIcon from '../assets/img/arrow-in-black-circle.svg';

export const HOME_LOGO_SRC = homeLogo;
export const ERROR_ROUND_ICON_SRC = errorRoundIcon;
export const CLOSED_EYE_ICON_SRC = closedEyeIcon;
export const OPENED_EYE_ICON_SRC = openedEyeIcon;
export const ARROW_ROUNDED_ICON_SRC = arrowRounded;
export const HEART_ICON_SRC = heart;
export const ACCEPT_ICON_SRC = acceptIcon;
export const CROSS_ICON_SRC = crossIcon;
export const USER_DEFAULT_AVATAR_ICON_SRC = userDefaultAvatarIcon;
export const GREEN_PLUS_ICON_SRC = greenPlusIcon;
export const GEAR_WHEEL_ICON_SRC = gearWheelIcon;
export const RECYCLE_BIN_ICON_SRC = recycleBinIcon;
export const HEART_RED_ICON_SRC = heartRedIcon;
export const FEEDBACK_ICON_SRC = feedbackIcon;
export const USER_ONLINE_ICON_SRC = userOnlineIcon;
export const OFFER_FEEDBACK_ICON_SRC = offerFeedbackIcon;
export const REMARK_FEEDBACK_ICON_SRC = remarkFeedbackIcon;
export const COMPLIMENT_FEEDBACK_ICON_SRC = complimentFeedbackIcon;
export const ARROW_IN_BLACK_CIRCLE_ICON_SRC = arrowInBlackCircleIcon;
