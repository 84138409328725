import { RegistrationQuestionType, CurrentLanguageType } from 'types/types';
import { language } from './language';
import { DEFAULT_EMAIL_INPUT_PLACEHOLDER } from 'constants/constants';

export const validateEmail = (email: string) => {
  if (!email) return false;
  const pattern = /^[^@\s]+@[^@\s]+\.[^@\s]{2,4}$/;
  if (!email.match(pattern) || email.length < 8) {
    return false;
  }
  return true;
};

export const hasForbiddenCharacters = (string: string) => {
  const FORBIDDEN_CHARACTERS = ['*', '/', '\\', ':', '?', '|', '"', "'", '`', '<', '>', ' '];
  const stringArray = string.split('');
  for (let i = 0; i < stringArray.length; i++) {
    if (FORBIDDEN_CHARACTERS.includes(stringArray[i])) return true;
  }
  return false;
};

interface IInputData {
  inputType: 'email' | 'password' | 'text';
  placeholder: string;
}

export const getInputData = (
  type: RegistrationQuestionType,
  isLoginInput: boolean,
  isRepeatedPassword: boolean,
  isPasswordShown: boolean,
  currentLanguage: CurrentLanguageType,
): IInputData => {
  const placeholder = (() => {
    switch (type) {
      case 'email':
        return !isLoginInput
          ? DEFAULT_EMAIL_INPUT_PLACEHOLDER
          : language(currentLanguage, 'yourEmailCap');
      case 'password':
        return language(currentLanguage, 'passwordCap');
      case 'nickname':
        return language(currentLanguage, 'nicknameCap');
      case 'check':
        return '';
    }
  })();
  const passwordInputType = isPasswordShown ? 'text' : 'password';
  switch (type) {
    case 'email':
      return { inputType: 'email', placeholder };
    case 'nickname':
      return { inputType: 'text', placeholder };
    case 'password':
      return { inputType: passwordInputType, placeholder };
    case 'check':
      return { inputType: 'text', placeholder };
  }
};
