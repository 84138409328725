import { FC } from 'react';

import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { RECYCLE_BIN_ICON_SRC } from 'constants/assets-src';
import { useThunks } from 'redux/thunks/hooks/useThunks';
import styles from './RemoveAvatarContainer.module.scss';

export const RemoveAvatarContainer: FC = () => {
  const language = useLanguage();
  const { updateUserAvatar } = useThunks();

  const removeUserAvatar = async () => {
    await updateUserAvatar();
  };
  return (
    <div className={styles.RemoveAvatarContainer} onClick={removeUserAvatar}>
      <img src={RECYCLE_BIN_ICON_SRC} alt={language('recycleBin')} />
    </div>
  );
};
