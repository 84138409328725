import { ChangeEvent, FC, useEffect, useState, useRef } from 'react';

import { Nullable } from 'types/types';
import { GREEN_PLUS_ICON_SRC } from 'constants/assets-src';
import { classNames } from 'helpers/classNames';
import styles from './UploadAvatarContainer.module.scss';

interface Props {
  handleUploadingAvatar: (uploadingAvatar: Nullable<File>) => void;
  uploadingAvatar: Nullable<File>;
}

export const UploadAvatarContainer: FC<Props> = ({ handleUploadingAvatar, uploadingAvatar }) => {
  const avatarUploadingInputRef = useRef<HTMLInputElement>(null);
  const [avatarPreviewSrc, setAvatarPreviewSrc] = useState<Nullable<string | ArrayBuffer>>(null);
  const uploadingAvatarSrc = !avatarPreviewSrc ? GREEN_PLUS_ICON_SRC : avatarPreviewSrc;

  const setUploadingAvatar = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const avatarFile = files[0];
    if (avatarFile) {
      const reader = new FileReader();
      reader.readAsDataURL(avatarFile);
      reader.onload = ({ target: { result } }) => {
        setAvatarPreviewSrc(result);
        handleUploadingAvatar(avatarFile);
      };
    }
  };

  useEffect(() => {
    if (!uploadingAvatar && avatarPreviewSrc) {
      setAvatarPreviewSrc(null);
      const avatarUploadingInput = avatarUploadingInputRef?.current;
      avatarUploadingInput && (avatarUploadingInput.files = null);
    }
  }, [avatarPreviewSrc, uploadingAvatar]);
  return (
    <div
      className={classNames(styles.UploadAvatarContainer, undefined, {
        [styles.notUploaded]: !avatarPreviewSrc,
      })}
    >
      <input
        className={styles.fileUploadInput}
        type="file"
        onChange={setUploadingAvatar}
        accept="image/*"
        multiple={false}
        title=""
        ref={avatarUploadingInputRef}
      />
      <img
        className={classNames(styles.uploadingAvatar, undefined, {
          [styles.notUploaded]: !avatarPreviewSrc,
        })}
        src={uploadingAvatarSrc as string}
        alt=""
      />
    </div>
  );
};
