import { ChangeEvent, FC, memo, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { EnterPressVoidMethod, LoginInputType } from 'types/types';
import { getIsPasswordRecoveryMode, setLoginData } from 'redux/slices/user/exports';
import { getCurrentLanguage } from 'redux/slices/main/exports';
import { getInputData } from 'utils/utils';
import { PasswordVisibilitySwitcher } from 'components/PasswordVisibilitySwitcher/PasswordVisibilitySwitcher';
import { useThunks } from 'redux/thunks/hooks/useThunks';
import { useLoginData } from 'pages/LoginPage/hooks/useLoginData';
import styles from './LoginInput.module.scss';

interface Props {
  type: LoginInputType;
  isLoginButtonDisabled: boolean;
}

export const LoginInput: FC<Props> = memo(({ type, isLoginButtonDisabled }) => {
  const dispatch = useAppDispatch();
  const { login, getPasswordRecoveryCode } = useThunks();
  const { loginData } = useLoginData();
  const currentLanguage = useAppSelector(getCurrentLanguage);
  const isPasswordRecoveryMode = useAppSelector(getIsPasswordRecoveryMode);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const isPasswordInput = type === 'password';
  const value = loginData[type];
  const isPasswordVisibilitySwitcherDisplayed = isPasswordInput && !!value;

  const onEnterHandler: EnterPressVoidMethod = (event) => {
    if (event.key !== 'Enter' || isLoginButtonDisabled) return;
    !isPasswordRecoveryMode || type === 'password' ? login() : getPasswordRecoveryCode();
  };
  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordShown(!isPasswordShown);
  }, [isPasswordShown]);
  const updateLoginData = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    dispatch(setLoginData({ ...loginData, [type]: value }));
  };
  const { inputType, placeholder: loginInputPlaceholder } = getInputData(
    type,
    true,
    false,
    isPasswordShown,
    currentLanguage,
  );
  return (
    <div className={styles.LoginInput}>
      <input
        type={inputType}
        value={value}
        placeholder={loginInputPlaceholder}
        spellCheck={false}
        onChange={updateLoginData}
        onKeyDown={onEnterHandler}
      />
      <PasswordVisibilitySwitcher
        isDisplayed={isPasswordVisibilitySwitcherDisplayed}
        isPasswordShown={isPasswordShown}
        onClickToggler={togglePasswordVisibility}
      />
    </div>
  );
});
