import { RegistrationQuestionNumberType } from 'pages/RegistrationPage/components/RegistrationSection/types';
import { CurrentlyViewedPageType } from 'redux/types';
import { AssessmentType, CurrentLanguageType, Nullable } from 'types/types';
import { USER_DEFAULT_AVATAR_ICON_SRC } from './assets-src';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_PORTABLE = navigator.maxTouchPoints > 0;
export const IS_DESKTOP = !navigator.maxTouchPoints;

export const ROOT_PATH_URL = '/';
export const AUTH_PAGE_URL = '/auth';
export const USER_ROOM_PAGE_URL = '/room';
export const FIRST_PROJECT_URL = '/sports';
export const SECOND_PROJECT_URL = '/pets';
export const THIRD_PROJECT_URL = '/health';
export const ABOUT_PAGE_URL = '/about';
export const PROJECTS_PAGES_DATA = [
  AUTH_PAGE_URL,
  FIRST_PROJECT_URL,
  SECOND_PROJECT_URL,
  THIRD_PROJECT_URL,
  ABOUT_PAGE_URL,
];
export const AUTH_PROJECTS_PAGES_DATA = [
  USER_ROOM_PAGE_URL,
  FIRST_PROJECT_URL,
  SECOND_PROJECT_URL,
  THIRD_PROJECT_URL,
  ABOUT_PAGE_URL,
];
export const SYSTEM_MESSAGE_AUTO_HIDE_DURATION = 5000;
export const SYSTEM_ERROR_AUTO_HIDE_DURATION = 15000;
export const PAGE_SWITCHER_ROLL_TIME = 3000;
export const PAGE_SWITCHER_FOLD_TIME = 10000;
export const LAST_REGISTRATION_QUESTION_NUMBER: RegistrationQuestionNumberType = 4;

export const EMPTY_STRING = '';
export const EMPTY_OBJECT = {};
export const ESCAPE_KEY_EVENT_NAME = 'Escape';
export const MOCK_REF = { current: { value: '' } };
export const BASE_TITLE = "Deguz's World";
export const MAIN_EMAIL = 'info@deguz.by';
export const EXTRA_EMAIL = 'deguz@mail.ru';

export const RECOVERY_CODE_AWAITING_TIME = 60000;
export const PASSWORD_RECOVERY_SESSION_AWAITING_TIME = 300000;

export const ASSESSMENT_DOCUMENT_SRC = (
  userId: number,
  assessmentType: AssessmentType,
  documentFileName: string,
) => `/${userId}/assessments/${assessmentType}/${documentFileName}`;
export const USER_AVATAR_SRC = (userId: number, avatarFileName: Nullable<string>) => {
  return !avatarFileName ? USER_DEFAULT_AVATAR_ICON_SRC : `/${userId}/avatar/${avatarFileName}`;
};

export const AVAILABLE_LANGUAGES: Array<CurrentLanguageType> = ['en', 'ru'];
export const ASSISTANT_GROUP_LINKS: Array<CurrentlyViewedPageType> = ['assistant'];
export const SIGN_IN_GROUP_LINKS: Array<CurrentlyViewedPageType> = ['signin', 'signup'];
export const INFO_GROUP_LINKS: Array<CurrentlyViewedPageType> = [
  'info',
  'support',
  'projects',
  'feedback',
];
export const DEFAULT_EMAIL_INPUT_PLACEHOLDER = 'mail@example.com';
