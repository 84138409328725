import { MutableRefObject } from 'react';

import { useGSAPTimeline } from 'hooks/GSAP/useGSAPTimeline';
import {
  BACKGROUND_COLOR_CONTAINER_DARK,
  PAGE_CHANGE_DURATION,
  ZERO_GSAP_DURATION,
} from 'constants/gsapConstants';

export const useAnimateAppearing = (
  isShown: boolean,
  arrowIConRef: MutableRefObject<HTMLImageElement>,
  buttonTextSpanRef: MutableRefObject<HTMLSpanElement>,
  scopeElement: MutableRefObject<HTMLDivElement>,
) => {
  const arrowIconYPosition = isShown ? 0 : '100%';
  const arrowAndTextOpacity = isShown ? 1 : 0;
  const buttonBoxShadow = isShown
    ? `0 0 2px 3px ${BACKGROUND_COLOR_CONTAINER_DARK}`
    : `0 0 0px 0px ${BACKGROUND_COLOR_CONTAINER_DARK}`;
  const appearingDuration = PAGE_CHANGE_DURATION;
  const uppArrowAnimationDelay = isShown ? 0 : appearingDuration;
  const buttonAndTextAnimationDelay = !isShown ? 0 : appearingDuration;
  const {
    timeline: upArrowAppearingTimeline,
    isFirstLoad: upArrowIsFirstLoad,
    setIsFirstLoad: upArrowSetIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isShown]);
  upArrowAppearingTimeline &&
    upArrowAppearingTimeline.to(arrowIConRef.current, {
      y: arrowIconYPosition,
      opacity: arrowAndTextOpacity,
      duration: upArrowIsFirstLoad ? ZERO_GSAP_DURATION : appearingDuration,
      delay: upArrowIsFirstLoad ? ZERO_GSAP_DURATION : uppArrowAnimationDelay,
      onComplete: () => {
        upArrowIsFirstLoad && upArrowSetIsFirstLoad(false);
      },
    });
  const { timeline, isFirstLoad, setIsFirstLoad } = useGSAPTimeline(
    false,
    undefined,
    scopeElement,
    [isShown],
  );
  timeline &&
    timeline.to(scopeElement.current, {
      boxShadow: buttonBoxShadow,
      duration: isFirstLoad ? ZERO_GSAP_DURATION : appearingDuration,
      delay: isFirstLoad ? ZERO_GSAP_DURATION : buttonAndTextAnimationDelay,
      onComplete: () => {
        isFirstLoad && setIsFirstLoad(false);
      },
    });
  const {
    timeline: buttonTextAppearingTimeline,
    isFirstLoad: buttonTextIsFirstLoad,
    setIsFirstLoad: buttonTextSetIsFirstLoad,
  } = useGSAPTimeline(false, undefined, scopeElement, [isShown]);
  buttonTextAppearingTimeline &&
    buttonTextAppearingTimeline.to(buttonTextSpanRef.current, {
      opacity: arrowAndTextOpacity,
      duration: isFirstLoad ? ZERO_GSAP_DURATION : appearingDuration,
      delay: buttonTextIsFirstLoad ? ZERO_GSAP_DURATION : buttonAndTextAnimationDelay,
      onComplete: () => {
        buttonTextIsFirstLoad && buttonTextSetIsFirstLoad(false);
      },
    });
};
