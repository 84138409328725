// API URLs
export const CHECK_EMAIL_AVAILABILITY_URL = `/api/user/email-check`;
export const USER_REGISTRATION_URL = `/api/user/registration`;
export const USER_LOGIN_URL = `/api/user/login`;
export const USER_AUTHORIZATION_CHECK_URL = `/api/user/authcheck`;
export const SEARCH_USERS_URL = `/api/user/search-users`;
export const GET_ONE_USER_INFO_URL = (userId: number) => `/api/user/get-user/${userId}`;
export const UPDATE_USER_AVATAR_URL = (userId: number) => `/api/user/update/avatar/${userId}`;
export const UPDATE_USER_ROLE_URL = (userId: number) => `/api/user/update/role/${userId}`;
export const UPDATE_USER_SEX_URL = (userId: number) => `/api/user/update/sex/${userId}`;
export const UPDATE_USER_PASSWORD_URL = (userId: number) => `/api/user/update/password/${userId}`;
export const UPDATE_USER_SPECIALIZATION_URL = (userId: number) =>
  `/api/user/update/specialization/${userId}`;
export const UPDATE_USER_INFORMATION_URL = (userId: number) =>
  `/api/user/update/information/${userId}`;
export const DELETE_USER_ACCOUNT_URL = (userId: number) => `/api/user/delete/${userId}`;
export const PASSWORD_RESTORE_URL = `/api/user/password-restore`;
export const RECOVERY_CODE_CHECK_URL = `/api/user/recovery-code-check`;
export const RECOVERY_PASSWORD_CHANGE_URL = `/api/user/recovery-session-check`;
export const GET_ASSESSMENTS_INFO_URL = `/api/user/assessments`;
export const APPROVE_ASSESSMENT_HANDLE_URL = (assessmentId: number) =>
  `/api/user/assessments/${assessmentId}`;
export const UPDATE_USER_MASTERY_URL = (userId: number) => `/api/user/update/mastery/${userId}`;
export const REMOVE_MASTERY_ASSESSMENT_URL = (userId: number, assessmentId: number) =>
  `/api/user/delete/mastery/${userId}/${assessmentId}`;
export const GET_ALL_FEEDBACKS_URL = `/api/feedback/get-all`;
export const ADD_FEEDBACK_URL = `/api/feedback/add`;
export const DELETE_ONE_FEEDBACK_URL = (feedbackId: number) => `/api/feedback/delete/${feedbackId}`;
export const DELETE_ALL_FEEDBACKS_URL = `/api/feedback/delete`;
