import { FC, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getModalWindowStatus, setModalWindowStatus } from 'redux/slices/main/exports';
import {
  getBackgroundStyles,
  getModalWindowContainerStyles,
  getModalWindowVisibilityStyles,
} from './transitionConstants';
import { useModalWindow } from './hooks/useModalWindow';
import { ACCEPT_ICON_SRC, CROSS_ICON_SRC } from 'constants/assets-src';
import { classNames } from 'helpers/classNames';
import styles from './ModalWindow.module.scss';

export const ModalWindow: FC = () => {
  const modalWindowRef = useRef<HTMLDivElement>(null);
  const modalWindowBackgroundRef = useRef<HTMLDivElement>(null);
  const modalWindowContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const modalWindowStatus = useAppSelector(getModalWindowStatus);
  const {
    isModalWindowAcceptingType,
    modalWindowAcceptMethod,
    modalWindowAcceptanceText,
    modalWindowExtraComponent,
    isModalWindowClosing,
    startClosingModalWindow,
    resetModalWindowState,
    isApplyButtonDisabled,
  } = useModalWindow(modalWindowStatus);
  const isModalWindowShown = modalWindowStatus !== 'closed' && !isModalWindowClosing;
  const {
    modalWindowVisibilityDefaultStyle,
    modalWindowVisibilityDuration,
    modalWindowVisibilityTransitionStyles,
  } = getModalWindowVisibilityStyles(isModalWindowShown);
  const { backgroundDefaultStyle, backgroundTransitionDuration, backgroundTransitionStyles } =
    getBackgroundStyles(isModalWindowShown);
  const {
    modalWindowContainerDefaultStyle,
    modalWindowContainerTransitionDuration,
    modalWindowContainerTransitionStyles,
  } = getModalWindowContainerStyles(isModalWindowShown);

  const closeModalWindow = () => {
    resetModalWindowState();
    dispatch(setModalWindowStatus('closed'));
  };
  return (
    <Transition
      in={isModalWindowShown}
      timeout={modalWindowVisibilityDuration}
      nodeRef={modalWindowRef}
      mountOnEnter
      unmountOnExit
      onExited={closeModalWindow}
    >
      {(state) => (
        <div
          className={styles.ModalWindow}
          style={{
            ...modalWindowVisibilityDefaultStyle,
            ...modalWindowVisibilityTransitionStyles[state],
          }}
          ref={modalWindowRef}
        >
          <Transition
            in={isModalWindowShown}
            timeout={modalWindowContainerTransitionDuration}
            nodeRef={modalWindowContainerRef}
            mountOnEnter
            unmountOnExit
          >
            <div
              className={styles.modalWindowContainerWrapper}
              style={{
                ...modalWindowContainerDefaultStyle,
                ...modalWindowContainerTransitionStyles[state],
              }}
              ref={modalWindowContainerRef}
            >
              <div className={styles.modalWindowContainer}>
                {modalWindowExtraComponent}
                {modalWindowAcceptanceText && <p>{modalWindowAcceptanceText}</p>}
              </div>
              <div
                className={styles.closeModalWindowButtonWrapper}
                onClick={startClosingModalWindow}
              >
                <div className={styles.closeModalWindowButton} role="button">
                  <img draggable={false} src={CROSS_ICON_SRC} alt="" />
                </div>
              </div>
              {isModalWindowAcceptingType && (
                <div className={styles.applyModalWindowButtonContainer}>
                  <div
                    className={classNames(styles.applyModalWindowButtonWrapper, undefined, {
                      [styles.disabled]: isApplyButtonDisabled,
                    })}
                  >
                    <div
                      className={styles.applyModalWindowButton}
                      role="button"
                      onClick={modalWindowAcceptMethod}
                    >
                      <img
                        draggable={false}
                        className={classNames(styles.applyButtonImage, undefined, {
                          [styles.disabled]: isApplyButtonDisabled,
                        })}
                        src={ACCEPT_ICON_SRC}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Transition>
          <Transition
            in={isModalWindowShown}
            timeout={backgroundTransitionDuration}
            nodeRef={modalWindowBackgroundRef}
          >
            <div
              className={styles.modalWindowBackground}
              style={{
                ...backgroundDefaultStyle,
                ...backgroundTransitionStyles[state],
              }}
              ref={modalWindowBackgroundRef}
            ></div>
          </Transition>
        </div>
      )}
    </Transition>
  );
};
