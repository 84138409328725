import { IObjectOfObjects } from 'types/types';
import { ITranslationsObject } from './index';
import { RoutesEnum } from 'router/routes';

export const MAIN_TRANSLATIONS: IObjectOfObjects<ITranslationsObject> = {
  authorization: {
    en: 'authorization',
    ru: 'авторизация',
  },
  yourEmailOptionallyCap: {
    en: '~Your ~email (optionally)',
    ru: '~Ваш ~email (опционально)',
  },
  thankYou: {
    en: 'Powered by your kindness!',
    ru: 'Благодаря вашей доброте!',
  },
  onceAgainCap: {
    en: 'Once again',
    ru: 'Ещё раз',
  },
  nextCap: {
    en: 'Next',
    ru: 'Далее',
  },
  sendCap: {
    en: 'Send',
    ru: 'Отправить',
  },
  openEye: {
    en: 'an open eye',
    ru: 'открытый глаз',
  },
  closedEye: {
    en: 'a closed eye',
    ru: 'закрытый глаз',
  },
  assistantPlaceholder: {
    en: "please wait, I'm ~working on it ;)",
    ru: 'погоди, я ~работаю над этим ;)',
  },
  translateToSignUpTextWithLink: {
    en: `~No ~account? It doesn't matter! Let's ^create_it!:${RoutesEnum.SIGN_UP_URL}`,
    ru: `~Нет ~аккаунта? Не беда! Давайте ^создадим_его!:${RoutesEnum.SIGN_UP_URL}`,
  },
  registrationQuestionEmail: {
    en: "~Hi! We will need ~your ~email address to register. Let's point it out:",
    ru: '~Привет! Для регистрации нам потребуется ~ваш ~email. Давайте укажем его:',
  },
  registrationQuestionNickname: {
    en: "Now we will indicate ~your ~nickname, in any case, it's time to come up with it:",
    ru: 'Теперь укажем ~ваш ~никнейм, в любом случае, настало время его придумать:',
  },
  registrationQuestionPassword: {
    en: "~It's ~almost ~done! The last step is to come up with a ~strong password:",
    ru: '~Почти ~готово! Последний шаг - придумать ~надёжный пароль:',
  },
  registrationQuestionCheck: {
    en: 'Everything is ~ready ~to ~be ~sent! If the registration data is correct, then you can now ~send them for registration. You can still ~change them.',
    ru: 'Всё ~готово ~для ~отправки! Если регистрационные данные верны, то вы можете теперь ~отправить их для регистрации. Вы всё еще можете ~изменить их.',
  },
  sportAssistantCap: {
    en: 'Sport Assistant',
    ru: 'Спорт Ассистент',
  },
  mySportCap: {
    en: 'My Sport',
    ru: 'Мой спорт',
  },
  forbiddenCharactersCap: {
    en: 'Forbidden characters',
    ru: 'Запрещённые символы',
  },
  inNickname: {
    en: 'in the nickname',
    ru: 'в никнейме',
  },
  inName: {
    en: 'in the name',
    ru: 'в имени',
  },
  inLastName: {
    en: 'in the last name',
    ru: 'в фамилии',
  },
  inNameOfCity: {
    en: 'in the name of the city',
    ru: 'в названии города',
  },
  inNameOfCountry: {
    en: 'in the name of the country',
    ru: 'в названии страны',
  },
  toSending: {
    en: 'to sending',
    ru: 'к отправке',
  },
  sportAssistantShortDescription: {
    en: 'sports assistant',
    ru: 'спортивный помощник',
  },
  infoCap: {
    en: 'Information',
    ru: 'Информация',
  },
  supportCap: {
    en: 'Support',
    ru: 'Поддержать',
  },
  supportNounCap: {
    en: 'Support',
    ru: 'Поддержка',
  },
  feedbackCap: {
    en: 'Feedback',
    ru: 'Отзыв',
  },
  projectsCap: {
    en: 'Projects',
    ru: 'Проекты',
  },
  signInCap: {
    en: 'Sign in',
    ru: 'Войти',
  },
  signUpCap: {
    en: 'Sign up',
    ru: 'Создать',
  },
  cancelCap: {
    en: 'Cancel',
    ru: 'Отмена',
  },
  resetCap: {
    en: 'Reset',
    ru: 'Сбросить',
  },
  project: {
    en: 'project',
    ru: 'проект',
  },
  registrationCap: {
    en: 'Registration',
    ru: 'Регистрация',
  },
  register: {
    en: 'register',
    ru: 'зарегистрировать',
  },
  registerCap: {
    en: 'Register',
    ru: 'Зарегистрировать',
  },
  logInCap: {
    en: 'Log in',
    ru: 'Вход',
  },
  room: {
    en: 'room',
    ru: 'кабинет',
  },
  passwordCap: {
    en: 'Password',
    ru: 'Пароль',
  },
  passwordConfirmCap: {
    en: 'Confirm password',
    ru: 'Подтвердить пароль',
  },
  forgotMyPassword: {
    en: 'forgot my password',
    ru: 'забыл свой пароль',
  },
  getCodeCap: {
    en: 'Get the code',
    ru: 'Получить код',
  },
  confirmCodeCap: {
    en: 'Confirm the code',
    ru: 'Подтвердить код',
  },
  returnToLoginCap: {
    en: 'Return to login',
    ru: 'Вернуться ко входу в систему',
  },
  or: {
    en: 'or',
    ru: 'или',
  },
  enterEmailMessageCap: {
    en: 'Enter email',
    ru: 'Введите email',
  },
  enterCorrectEmailMessageCap: {
    en: 'Enter the correct email',
    ru: 'Введите корректный email',
  },
  aboutProject: {
    en: 'about project',
    ru: 'о проекте',
  },
  nicknameCap: {
    en: 'Nickname',
    ru: 'Никнейм',
  },
  backCap: {
    en: 'Back',
    ru: 'Назад',
  },
  information: {
    en: 'information',
    ru: 'информация',
  },
  ['passwordsDon`tMatchCap']: {
    en: `Passwords don't match`,
    ru: 'Пароли не совпадают',
  },
  enterEmailForLoginCap: {
    en: 'Enter your email to log in',
    ru: 'Введите email для входа',
  },
  enterEmailForRecoveryCap: {
    en: 'Enter your email to recover your password',
    ru: 'Введите email для восстановления пароля',
  },
  enterEmailForRegistrationCap: {
    en: 'Enter email for registration',
    ru: 'Введите email для регистрации',
  },
  specifiedEmailIncorrect: {
    en: 'the specified email is incorrect',
    ru: 'указанный email - некорректный',
  },
  enterPasswordForLoginCap: {
    en: 'Enter the password to log in',
    ru: 'Введите пароль для входа',
  },
  enterPasswordForRegistrationCap: {
    en: 'Enter the password for registration',
    ru: 'Введите пароль для регистрации',
  },
  minPasswordLength8CharsCap: {
    en: 'The minimum password length is 8 characters',
    ru: 'Минимальная длина пароля - 8 символов',
  },
  enterNicknameForRegistrationCap: {
    en: 'Enter a nickname to register',
    ru: 'Введите никнейм для регистрации',
  },
  minNicknameLength2CharsCap: {
    en: 'The minimum nickname length is 2 characters',
    ru: 'Минимальная длина никнейма - 2 символа',
  },
  maxNicknameLength10CharsCap: {
    en: 'The maximum nickname length is 10 characters',
    ru: 'Максимальная длина никнейма - 10 символов',
  },
  logout: {
    en: 'log out',
    ru: 'выйти',
  },
  doYoWantLogoutCap: {
    en: 'Do you want to log out?',
    ru: 'Вы хотите выйти из системы?',
  },
  partners: {
    en: 'Partners',
    ru: 'Партнёры',
  },
  confirmUsersMasteryCap: {
    en: "Confirm the user's mastery?",
    ru: 'Подтвердить мастерство пользователя?',
  },
  yesCap: {
    en: 'Yes',
    ru: 'Да',
  },
  youSuccessfullyLoggedOut: {
    en: 'You have successfully logged out',
    ru: 'Вы успешно вышли',
  },
  sport: {
    en: 'sport',
    ru: 'спорт',
  },
  health: {
    en: 'health',
    ru: 'здоровье',
  },
  searchCap: {
    en: 'Search',
    ru: 'Поиск',
  },
  youCap: {
    en: 'You',
    ru: 'Вы',
  },
  trainingDiaryCap: {
    en: 'Training diary',
    ru: 'Дневник тренировок',
  },
  later: {
    en: 'later...',
    ru: 'позже...',
  },
  baseTitleCap: {
    en: "Deguz's World",
    ru: 'Мир Deguz',
  },
  authorizationNeededCap: {
    en: 'Authorization needed',
    ru: 'Требуется авторизация',
  },
  doctor: {
    en: 'doctor',
    ru: 'врач',
  },
  sportsmen: {
    en: 'sportsmen',
    ru: 'спортсмен',
  },
  trainer: {
    en: 'trainer',
    ru: 'тренер',
  },
  settings: {
    en: 'settings',
    ru: 'настройки',
  },
  informationCap: {
    en: 'Information',
    ru: 'Информация',
  },
  specializationCap: {
    en: 'Specialization',
    ru: 'Специализация',
  },
  roleCap: {
    en: 'Role',
    ru: 'Роль',
  },
  deletionCap: {
    en: 'Deletion',
    ru: 'Удаление',
  },
  masteryCap: {
    en: 'Mastery',
    ru: 'Мастерство',
  },
  enterCurrentPasswordCap: {
    en: 'Enter the current password',
    ru: 'Введите текущий пароль',
  },
  enterNewPasswordCap: {
    en: 'Enter a new password',
    ru: 'Введите новый пароль',
  },
  newPasswordCap: {
    en: 'New password',
    ru: 'Новый пароль',
  },
  confirmNewPasswordCap: {
    en: 'Repeat the new password',
    ru: 'Подтвердите новый пароль',
  },
  repeatPasswordCap: {
    en: 'Repeat the password',
    ru: 'Повторите пароль',
  },
  clearCap: {
    en: 'Clear',
    ru: 'Очистить',
  },
  doYouWantDowngradeYourRoleCap: {
    en: 'Do you want to downgrade your role?',
    ru: 'Вы хотите понизить свою роль?',
  },
  doYouWantDeleteYourAccountCap: {
    en: 'Do you want to delete your account?',
    ru: 'Вы хотите удалить свой аккаунт?',
  },
  downgradeYourRoleCap: {
    en: 'Downgrade your role',
    ru: 'Понизить свою роль',
  },
  search: {
    en: 'search',
    ru: 'поиск',
  },
  enterNewNicknameCap: {
    en: 'Enter a new nickname',
    ru: 'Укажите новый никнейм',
  },
  enterNewEmailCap: {
    en: 'Enter a new email',
    ru: 'Укажите новый email',
  },
  enterNewCityCap: {
    en: 'Enter a new city',
    ru: 'Укажите новый город',
  },
  enterNewCountryCap: {
    en: 'Enter a new country',
    ru: 'Укажите новую страну',
  },
  enterNewName: {
    en: 'Enter a new name',
    ru: 'Укажите новое имя',
  },
  enterNewLastName: {
    en: 'Enter a new last name',
    ru: 'Укажите новую фамилию',
  },
  dd: {
    en: 'dd',
    ru: 'дд',
  },
  mm: {
    en: 'mm',
    ru: 'мм',
  },
  yyyy: {
    en: 'yyyy',
    ru: 'гггг',
  },
  enterCorrectBirthDate: {
    en: 'Enter the correct date of birth',
    ru: 'Укажите корректную дату рождения',
  },
  deleteAccountCap: {
    en: 'Delete Account',
    ru: 'Удалить аккаунт',
  },
  recoverCap: {
    en: 'Recover',
    ru: 'Восстановить',
  },
  checkCap: {
    en: 'Check',
    ru: 'Проверить',
  },
  changeCap: {
    en: 'Change',
    ru: 'Изменить',
  },
  enterCodeCap: {
    en: 'Enter the code',
    ru: 'Введите код',
  },
  minute: {
    en: 'minute',
    ru: 'минута',
  },
  minutes: {
    en: 'minutes',
    ru: 'минут',
  },
  noActionsAvailableCap: {
    en: 'No actions available',
    ru: 'Нет доступных действий',
  },
  masteryAssessmentsCap: {
    en: 'Mastery assessments',
    ru: 'Подтверждения мастерства',
  },
  declaresMastery: {
    en: 'declares mastery',
    ru: 'заявляет мастерство',
  },
  approveCap: {
    en: 'Approve',
    ru: 'Одобрить',
  },
  waitingCap: {
    en: 'Waiting',
    ru: 'Ожидает',
  },
  processing: {
    en: 'processing',
    ru: 'обработка',
  },
  assessmentRegistrationCap: {
    en: 'Assessment registration',
    ru: 'Регистрация подтверждения',
  },
  documentPreview: {
    en: 'document preview',
    ru: 'предварительное изображение документа',
  },
  attachDocument: {
    en: 'attach the document',
    ru: 'приложите документ',
  },
  deleteMasteryConfirmationRequestCap: {
    en: 'Delete the mastery confirmation request?',
    ru: 'Удалить запрос подтверждения мастерства?',
  },
  doYouWantToRemoveMastery: {
    en: 'Do you want to remove the mastery?',
    ru: 'Вы хотите удалить мастерство?',
  },
  errorCap: {
    en: 'Error',
    ru: 'Ошибка',
  },
  weLoggingInCap: {
    en: 'We are logging in',
    ru: 'Авторизуемся',
  },
  sendFeedbackCap: {
    en: 'Send feedback',
    ru: 'Отправить отзыв',
  },
  yourNameCap: {
    en: 'Your name',
    ru: 'Ваше имя',
  },
  emailOptionalCap: {
    en: 'Email (optional)',
    ru: 'Email (необязательно)',
  },
  yourEmailCap: {
    en: 'Your email',
    ru: 'Ваш email',
  },
  offer: {
    en: 'offer',
    ru: 'предложение',
  },
  remark: {
    en: 'remark',
    ru: 'замечание',
  },
  compliment: {
    en: 'compliment',
    ru: 'комплимент',
  },
  feedbackHighlightedCap: {
    en: '~Feedback',
    ru: '~Отзыв',
  },
  feedbacksCap: {
    en: 'Feedbacks',
    ru: 'Отзывы',
  },
  feedbackTypeChoosingText: {
    en: 'You can ~change ~the ~nature ~of ~the ~feedback to better meet your expectations',
    ru: 'Вы можете ~изменить ~характер ~отзыва, чтобы он лучше соответствовал вашим ожиданиям',
  },
  writeYourFeedbackInTheFieldBelowCap: {
    en: 'Write ~your ~feedback in the field below',
    ru: 'Напишите ~ваш ~отзыв в поле ниже',
  },
  yourFeedbackCap: {
    en: 'Your feedback',
    ru: 'Ваш отзыв',
  },
  discoverCap: {
    en: 'Discover',
    ru: 'Обзор',
  },
  from: {
    en: 'from',
    ru: 'от',
  },
  contentCap: {
    en: 'Content',
    ru: 'Содержание',
  },
};
