import {
  BACKGROUND_COLOR_ALTERNATIVE,
  SECONDARY_TEXT_COLOR,
  TEXT_COLOR_DARK,
} from 'constants/gsapConstants';

export const REGISTRATION_QUESTION_ANIMATION_BASE_DURATION = 0.3;

export const REGISTRATION_APPLY_BUTTON_BG_COLOR_DISABLED = 'rgba(255, 255, 255, 0.05)';
export const REGISTRATION_APPLY_BUTTON_BG_COLOR = BACKGROUND_COLOR_ALTERNATIVE;

export const REGISTRATION_APPLY_BUTTON_TEXT_COLOR_DISABLED = SECONDARY_TEXT_COLOR;
export const REGISTRATION_APPLY_BUTTON_TEXT_COLOR = TEXT_COLOR_DARK;
