import { FC, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { useAppDispatch } from 'redux/hooks';

import { CurrentlyViewedPageType } from 'redux/types';
import { useHomePageWidgetLink } from './hooks/useHomePageWidgetLink';
import { useLanguage } from 'hooks/useLanguage/useLanguage';
import { setAreHomePageWidgetsActive, setCurrentlyViewedPage } from 'redux/slices/main/exports';
import { getWidgetMainText } from './utils';
import { classNames } from 'helpers/classNames';
import { getHomePageWidgetBorderStyles } from './transitionConstants';
import { FEEDBACK_ICON_SRC, HEART_RED_ICON_SRC } from 'constants/assets-src';
import styles from './HomePageWidget.module.scss';

interface Props {
  linkSrc: CurrentlyViewedPageType;
}

export const HomePageWidget: FC<Props> = ({ linkSrc }) => {
  const homePageWidgetRef = useRef<HTMLAnchorElement>(null);
  const dispatch = useAppDispatch();
  const language = useLanguage();
  const { actualLinkSrc, cursor, areHomePageWidgetsActive } = useHomePageWidgetLink(linkSrc);
  const widgetMainText = getWidgetMainText(linkSrc, language);
  const isMainAppWidget = linkSrc === 'assistant';
  const mainAppWidgetShortDescription = language('sportAssistantShortDescription');
  const [isTransitionStarted, setIsTransitionStarted] = useState(false);
  const isHeartImage = linkSrc === 'support';
  const isFeedbackImage = linkSrc === 'feedback';

  const backgroundImageSrc = (() => {
    switch (linkSrc) {
      case 'support':
        return HEART_RED_ICON_SRC;
      case 'feedback':
        return FEEDBACK_ICON_SRC;
    }
  })();

  const {
    homePageWidgetBorderDefaultStyle,
    homePageWidgetBorderTransitionDuration,
    homePageWidgetBorderTransitionStyles,
  } = getHomePageWidgetBorderStyles();

  const switchCurrentlyViewedPage = () => {
    dispatch(setAreHomePageWidgetsActive(false));
    dispatch(setCurrentlyViewedPage(linkSrc));
  };
  const onClick = areHomePageWidgetsActive ? switchCurrentlyViewedPage : undefined;

  useEffect(() => {
    setIsTransitionStarted(true);
  }, [isTransitionStarted]);
  return (
    <Transition
      in={isTransitionStarted}
      timeout={homePageWidgetBorderTransitionDuration}
      nodeRef={homePageWidgetRef}
    >
      {(state) => (
        <NavLink
          draggable={false}
          to={actualLinkSrc}
          style={{
            cursor,
            ...homePageWidgetBorderDefaultStyle,
            ...homePageWidgetBorderTransitionStyles[state],
          }}
          className={classNames(styles.HomePageWidget, undefined, {
            [styles.mainAppWidget]: isMainAppWidget,
          })}
          onClick={onClick}
          ref={homePageWidgetRef}
        >
          <p
            className={classNames(styles.widgetMainText, undefined, {
              [styles.mainApp]: isMainAppWidget,
            })}
          >
            {widgetMainText}
          </p>
          {isMainAppWidget ? (
            <p className={styles.mainAppWidgetDescriptionText}>{mainAppWidgetShortDescription}</p>
          ) : null}
          {backgroundImageSrc && (
            <img
              className={classNames(styles.backgroundImage, undefined, {
                [styles.heart]: isHeartImage,
                [styles.feedback]: isFeedbackImage,
              })}
              src={backgroundImageSrc}
            />
          )}
        </NavLink>
      )}
    </Transition>
  );
};
