import { FC } from 'react';

import './SmallLoader.scss';

export const SmallLoader: FC = () => {
  return (
    <div className="small-loader-wrapper">
      <div className="point first"></div>
      <div className="point second"></div>
      <div className="point third"></div>
      <div className="point fourth"></div>
    </div>
  );
};
