import { FC, memo, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useAppSelector } from 'redux/hooks';
import { NavLink as NavLinkRouter } from 'react-router-dom';

import { getCurrentLanguage } from 'redux/slices/main/exports';
import { language } from 'utils/language';
import { CurrentlyViewedPageType } from 'redux/types';
import { classNames } from 'helpers/classNames';
import { getIsAuth } from 'redux/slices/user/exports';
import { NavLinkGroupType } from '../../types';
import { useLinksGroup } from 'hooks/useLinksGroup';
import { RoutesEnum } from 'router/routes';
import { getNavLinkColorStyles, getNavLinkOpacityStyles } from './transitionConstants';
import styles from './NavLink.module.scss';

interface Props {
  origin: NavLinkGroupType;
  linkName: string;
  linkSrc: CurrentlyViewedPageType;
  isMainLinkInGroup?: boolean;
  isAppLink?: boolean;
  iconSrc?: string;
  iconAltText?: string;
  isAuthorizationLinksGroup?: boolean;
}

export const NavLink: FC<Props> = memo(
  ({
    origin,
    linkName,
    linkSrc,
    isAppLink,
    isMainLinkInGroup,
    iconSrc,
    iconAltText,
    isAuthorizationLinksGroup,
  }) => {
    const navLinkRef = useRef(null);
    const navLinkSpanRef = useRef(null);
    const isAuth = useAppSelector(getIsAuth);
    const currentLanguage = useAppSelector(getCurrentLanguage);
    const { isNavLinksGroupCurrent, isThisPageCurrent } = useLinksGroup(origin, linkSrc);
    const isAuthorizationHiddenGroup = isAuth && isAuthorizationLinksGroup;
    const isVisible = isMainLinkInGroup || isNavLinksGroupCurrent;
    const isDisplayed = (isThisPageCurrent || isVisible) && !isAuthorizationHiddenGroup;
    const isClickable = !isThisPageCurrent && isDisplayed;
    const applicableLinkSrc = isClickable ? `/${linkSrc}` : RoutesEnum.ROOT_PATH_URL;

    const { navLinkOpacityDuration, navLinkOpacityDefaultStyle, navLinkOpacityTransitionStyles } =
      getNavLinkOpacityStyles(isNavLinksGroupCurrent);
    const { navLinkColorDefaultStyle, navLinkColorDuration, navLinkColorTransitionStyles } =
      getNavLinkColorStyles(isAppLink);
    return (
      <Transition in={!isDisplayed} timeout={navLinkOpacityDuration} nodeRef={navLinkRef}>
        {(state) => (
          <NavLinkRouter
            draggable={false}
            to={applicableLinkSrc}
            style={{
              cursor: isClickable ? 'pointer' : 'default',
              ...navLinkOpacityDefaultStyle,
              ...navLinkOpacityTransitionStyles[state],
            }}
            className={classNames(styles.NavLink, undefined, {
              [styles.appLink]: isAppLink,
              [styles.notClickable]: !isClickable,
            })}
            ref={navLinkRef}
          >
            <Transition
              in={!isThisPageCurrent}
              timeout={navLinkColorDuration}
              nodeRef={navLinkSpanRef}
            >
              {(state) => (
                <span
                  style={{
                    ...navLinkColorDefaultStyle,
                    ...navLinkColorTransitionStyles[state],
                  }}
                  ref={navLinkSpanRef}
                >
                  {language(currentLanguage, linkName)}
                </span>
              )}
            </Transition>
            {iconSrc && (
              <img src={iconSrc} alt={iconAltText ? language(currentLanguage, iconAltText) : ''} />
            )}
          </NavLinkRouter>
        )}
      </Transition>
    );
  },
);
