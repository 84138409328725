import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { getUserAvatar, getUserRole } from 'redux/slices/user/exports';
import { classNames } from 'helpers/classNames';
import { UserAvatarImage } from './components/UserAvatarImage/UserAvatarImage';
import { GEAR_WHEEL_ICON_SRC, GREEN_PLUS_ICON_SRC } from 'constants/assets-src';
import { setModalWindowStatus } from 'redux/slices/main/exports';
import { GenderSymbol } from 'components/GenderSymbol/GenderSymbol';
import styles from './UserAvatar.module.scss';

export const UserAvatar: FC = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getUserRole);
  const userAvatar = useAppSelector(getUserAvatar);
  const isCreator = userRole === 'CREATOR';
  const isAdmin = userRole === 'ADMIN';
  const avatarStateIconSrc = userAvatar ? GEAR_WHEEL_ICON_SRC : GREEN_PLUS_ICON_SRC;

  const openUploadingUserAvatarModalWindow = () => {
    dispatch(setModalWindowStatus('avatar'));
  };
  return (
    <div
      className={classNames(styles.UserAvatar, undefined, {
        [styles.creator]: isCreator,
        [styles.admin]: isAdmin,
      })}
      onClick={openUploadingUserAvatarModalWindow}
    >
      <GenderSymbol mode="icon" />
      <UserAvatarImage />
      <img className={styles.avatarStateIcon} src={avatarStateIconSrc} alt="" />
    </div>
  );
};
