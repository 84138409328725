import { FC, useCallback, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';

import { getUserNickname } from 'redux/slices/user/exports';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import { getIsUserSideMenuOpened } from 'redux/slices/main/exports';
import { ESCAPE_KEY_EVENT_NAME } from 'constants/constants';
import { GenderSymbol } from 'components/GenderSymbol/GenderSymbol';
import styles from './UserSideMenuHead.module.scss';

interface Props {
  closeUserSideMenu: VoidFunction;
}

export const UserSideMenuHead: FC<Props> = ({ closeUserSideMenu }) => {
  const isUserSideMenuOpened = useAppSelector(getIsUserSideMenuOpened);
  const userNickname = useAppSelector(getUserNickname);

  const closeModalWindowOnEscKey = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      key === ESCAPE_KEY_EVENT_NAME && closeUserSideMenu();
    },
    [closeUserSideMenu],
  );

  useEffect(() => {
    if (isUserSideMenuOpened) {
      window.addEventListener('keydown', closeModalWindowOnEscKey);
    } else {
      window.removeEventListener('keydown', closeModalWindowOnEscKey);
    }
    return () => window.removeEventListener('keydown', closeModalWindowOnEscKey);
  }, [closeModalWindowOnEscKey, isUserSideMenuOpened]);
  return (
    <div className={styles.UserSideMenuHead}>
      <UserAvatar />
      <div className={styles.userNicknameAndGenderChangerContainer}>
        <span className={styles.userNicknameSpan}>{userNickname}</span>
        <div className={styles.genderChangerContainer}>
          <GenderSymbol type="male" mode="switcher" />
          <GenderSymbol type="female" mode="switcher" />
        </div>
      </div>
      <span className={styles.closeSideMenuSpan} onClick={closeUserSideMenu}>
        &times;
      </span>
    </div>
  );
};
