export enum GSAPEasingEnum {
  NONE = 'none',
  POWER1_OUT = 'power1.out',
  POWER1_IN = 'power1.in',
  POWER1_IN_OUT = 'power1.inOut',
  POWER2_OUT = 'power2.out',
  POWER2_IN = 'power2.in',
  POWER2_IN_OUT = 'power2.inOut',
  POWER3_OUT = 'power3.out',
  POWER3_IN = 'power3.in',
  POWER3_IN_OUT = 'power3.inOut',
  POWER4_OUT = 'power4.out',
  POWER4_IN = 'power4.in',
  POWER4_IN_OUT = 'power4.inOut',
  BACK_OUT_PARAM = 'back.out',
  BACK_IN_PARAM = 'back.in',
  BACK_IN_OUT_PARAM = 'back.inOut',
  BOUNCE_OUT = 'bounce.out',
  BOUNCE_IN = 'bounce.in',
  BOUNCE_IN_OUT = 'bounce.inOut',
  CIRC_OUT = 'circ.out',
  CIRC_IN = 'circ.in',
  CIRC_IN_OUT = 'circ.inOut',
  ELASTIC_OUT_PARAM = 'elastic.out',
  ELASTIC_IN_PARAM = 'elastic.in',
  ELASTIC_IN_OUT_PARAM = 'elastic.inOut',
  EXPO_OUT = 'expo.out',
  EXPO_IN = 'expo.in',
  EXPO_IN_OUT = 'expo.inOut',
  SINE_OUT = 'sine.out',
  SINE_IN = 'sine.in',
  SINE_IN_OUT = 'sine.inOut',
  STEPS_PARAM = 'steps',
}

export const ZERO_GSAP_DURATION = 0;
export const PAGE_CHANGE_DURATION = 0.4;

export const MAIN_THEME_COLOR = '#0078d7';
export const ACCENTS_THEME_COLOR = '#9c30e3';
export const BACKGROUND_COLOR_DARK = '#070707';
export const BACKGROUND_COLOR_ALTERNATIVE = '#4A0072';
export const BACKGROUND_COLOR_CONTAINER_DARK = '#151515';
export const TEXT_COLOR_DARK = '#d3d3d3';
export const SECONDARY_TEXT_COLOR = '#555';
export const POSITIVE_TEXT_COLOR = '#16c23f';
export const NEGATIVE_COLOR = '#c21631';
export const WARNING_TEXT_COLOR = '#c2a216';
